import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import {
  entitiesPropsFactory,
  getActiveId,
  getAllEntities,
  getEntity,
  selectActiveEntity,
  selectAllEntities,
  selectEntity,
  selectMany,
  setActiveId,
  setEntities,
  updateEntities,
  withActiveId,
  withEntities
} from '@ngneat/elf-entities';
import { selectIsRequestPending, updateRequestStatus, withRequestsStatus } from '@ngneat/elf-requests';
import { catchError, combineLatestWith, from, Observable, switchMap, tap, throwError } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MenuPrice } from '../../api/v1/models/menu-price';
import { MenuDto } from '../../api/v1/models/menu-dto';
import { MenuCategoryDto } from '../../api/v1/models/menu-category-dto';
import { MenuItemDto } from '../../api/v1/models/menu-item-dto';
import { MenuItemSizeDto } from '../../api/v1/models/menu-item-size-dto';
import { MenuService } from '../../api/v1/services/menu.service';
import { SettingsService } from '../../api/v1/services/settings.service';
import { SeoService } from '../../services/seo.service';
import { StatusMessageService } from '../services/status-message.service';
import { ConfigRepository } from './config.repository';
import { UIMenuItemDto } from '../interfaces/ui-menu-item-dto.interface';
import { Location } from '@angular/common';
import { RelatedProduct } from '../../api/v1/models/related-product';
import { PastOrderItemDto } from '../../api/v1/models/past-order-item-dto';
import { getCurrentDayName, getTimeZoneOffset } from '../utils/get-time-intervals';
import { DayNamesType } from '../interfaces/day-names.type';
import { UIMenuCategoryDto } from '../interfaces/ui-menu-category-dto.interface';
import { MenuScheduleDto } from '../../api/v1/models/menu-schedule-dto';

export interface MenuListModel {
  stopList: Map<string, number>,
  itemsSlugToIdMap: {[key: string]: {[key: string]: string}} | null
  categorySlugToIdMap: Map<string, string> | null
  itemIdToUniqKeys: {[key: string]: string[]} | null
  searchString: string | null,
  unavailableCategories: string[],
}

const { itemsEntitiesRef, withItemsEntities } = entitiesPropsFactory('items');

const menuStore = createStore({ name: 'menu' },
  withProps<MenuListModel>({
    stopList: new Map<string, number>(),
    itemsSlugToIdMap: null,
    categorySlugToIdMap: null,
    itemIdToUniqKeys: null,
    searchString: null,
    unavailableCategories: [],
  }),
  withEntities<UIMenuCategoryDto>(),
  withActiveId(),
  withItemsEntities<UIMenuItemDto, 'uniqKey'>({idKey: 'uniqKey'}),
  withRequestsStatus<'isLoadingMenu'>(),
);

@Injectable({ providedIn: 'root' })
export class MenuListRepository {
  constructor(
    private configRepository: ConfigRepository,
    private api: SettingsService,
    private statusMessage: StatusMessageService,
    private menuService: MenuService,
    private seoService: SeoService,
    private router: Router,
    private location: Location
  ) {}

  searchString$: Observable<string | null> = menuStore.pipe(select(state => state.searchString));

  categories$: Observable<UIMenuCategoryDto[] | null> = menuStore.pipe(selectAllEntities());
  categoryItems$: Observable<UIMenuItemDto[] | null> = menuStore.pipe(selectAllEntities({ ref: itemsEntitiesRef }));
  menuNavCategories$: Observable<UIMenuCategoryDto[] | null> = menuStore.pipe(
    select(state => state.unavailableCategories),
    combineLatestWith(this.categories$),
    map(([unavailableIds, categories]) => {
      if(!categories){return null}

      const mapped = categories.map((category, i) => {
        category.isScheduleAvailable = unavailableIds.includes(category.id);
        return {index: i, value: category.isScheduleAvailable ? 1 : -1}
      });

      mapped.sort((a, b) => {return a.value - b.value})

      return mapped.map((item => categories[item.index]));
    }),
    map((categories: UIMenuCategoryDto[] | null) => {
      if(!categories){return null}
      categories.forEach(category => {
        if(category.buttonImage && category.buttonImage['src']){return}

        for (let i = 0; i < category.itemsIDs.length; i++){
          const item = menuStore.query(getEntity(category.itemsIDs[i], {ref: itemsEntitiesRef}));
          if(item && item.itemSizes[0] && item.itemSizes[0].buttonImage){
            category.buttonImage = item.itemSizes[0].buttonImage;
            break;
          }
        }
      })
      return categories;
    })
  )

  public activeCategory$: Observable<UIMenuCategoryDto> = menuStore.pipe(
    selectActiveEntity(),
    filter((category): category is UIMenuCategoryDto => !!category),
    tap(data => {
      this.seoService.setDynamicDataMetaTags(data.name, data.description);
    })
  );

  activeCategorySchedule$: Observable<MenuScheduleDto | null> = menuStore.pipe(
    selectActiveEntity(),
    filter((category): category is UIMenuCategoryDto => !!category),
    map(category => this.getAvailableRange(category.schedule, getCurrentDayName())),
    map(schedule => {
      if(schedule){
        schedule.from = schedule.from.slice(0, 5);
        schedule.to = schedule.to.slice(0, 5);
      }
      return schedule
    })
  );

  public activeCategoryId$: Observable<string> = this.activeCategory$.pipe(map(category => category.id || ''));

  public activeCategoryItems$: Observable<UIMenuItemDto[]> = this.activeCategory$.pipe(
    switchMap(category => this.getItemsByUniqKey(category!.itemsIDs)),
    filter((items): items is UIMenuItemDto[] => !!items),
    map(items => this.filterNullPrice(items)),
    map(items => this.sortMenuItems(items)),
  );
  
  menuNavCategoryItems$: Observable<UIMenuItemDto[]> = this.menuNavCategories$.pipe(
    filter((categories): categories is UIMenuCategoryDto[] => !!categories),
    switchMap(categories =>
      this.getItemsByUniqKey(categories.reduce((arr, category) => arr.concat(category.itemsIDs), [] as string[]))
    ),
    filter((items): items is UIMenuItemDto[] => !!items),
    map(items => this.filterNullPrice(items)),
    map(items => this.sortMenuItems(items)),
  );

  getSearchedItems(searchString: string): Observable<UIMenuItemDto[]> {
    return menuStore.pipe(selectAllEntities({ref: itemsEntitiesRef})).pipe(
      map(items => {
        return items.filter(item => {
          return item.name.toLowerCase().includes(searchString.toLowerCase());
        })
      })
    )
  }

  showItems$: Observable<UIMenuItemDto[] | null> = this.searchString$.pipe(
    switchMap(searchString => {
      if(searchString){
        return this.getSearchedItems(searchString);
      }
      return this.menuNavCategoryItems$;
    })
  )

  public loadingMenuStatus$: Observable<boolean> = menuStore.pipe(selectIsRequestPending('isLoadingMenu'));

  public stopListItems$: Observable<Map<string, number>> = menuStore.pipe(select(state => state.stopList));

  get activeCategoryId(): string {
    return menuStore.query(getActiveId);
  }

  categoryItemsByUniqKey(keys: string[]): Observable<UIMenuItemDto[]> {
    return this.getItemsByUniqKey(keys).pipe(
      filter((items): items is UIMenuItemDto[] => !!items),
      map(items => this.filterNullPrice(items)),
      map(items => this.sortMenuItems(items)),
    )
  }

  private getItemsByUniqKey(keys: string[]): Observable<UIMenuItemDto[]>{
    return menuStore.pipe(selectMany(keys, {ref: itemsEntitiesRef}))
  }

  fetchMenu(): void {
    this.fetchMenuList().pipe(
      switchMap(() => this.configRepository.activeOutletId$),
      filter(storeId => !!storeId),
      switchMap(() => this.fetchStopList())
    ).subscribe()
  }

  private getMenuObservable(): Observable<MenuDto> {
    if(typeof Worker !== 'undefined' && typeof window !== 'undefined'){
      return from((window as any)['menuFetchPromise']) as Observable<MenuDto>;
    }
    return this.api.v1SettingsGetMenuGet$Json().pipe(
      map(response => {
        if(response.error || !response.result) {
          throw response;
        }
        return response.result;
      })
    )
  }

  fetchMenuList(): Observable<MenuDto> {
    menuStore.update(updateRequestStatus('isLoadingMenu', 'pending'));
    return this.getMenuObservable().pipe(
      map((menu => {
        if('error' in menu) {
          throw new Error('menu is not found');
        }

        return menu;
      })),
      tap(data => {
        menuStore.update(updateRequestStatus('isLoadingMenu', 'success'));

        if(!data.itemCategories) {return}
        this.setList(data.itemCategories);
        this.setActiveCatalogUrl();
      }),

      catchError(error => {
        this.statusMessage.setStatusMessage({
          title: $localize `:@@MessageModalTitleError:Error`,
          message: $localize `:@@FailedToGetMenu:Failed to get menu. Reload the page or try again later.`,
          type: 'error'
        });

        menuStore.update(updateRequestStatus('isLoadingMenu', 'error', error));

        return throwError(error);
      })
    )
  }

  private setList(menuList: MenuCategoryDto[]): void {
    const UIMenuList: UIMenuCategoryDto[] = [];
    const unavailableCategories: string[] = [];
    const menuItems: UIMenuItemDto[] = [];

    const itemsSlugMap: { [key: string]: {[key: string]: string} } = {};
    const categorySlugMap: Map<string, string> = new Map<string, string>();
    const itemIdToUniqKeys: { [key: string]: string[] } = {};

    menuList.forEach(category => {
      if(category.isHidden){return}

      if(!this.checkCategoryAvailable(category)){
        unavailableCategories.push(category.id);
      }
      else {
        categorySlugMap.set(category.slug, category.id)
      }

      const itemsIDs: string[] = [];
      const categoryItemsSlug: {[key: string]: string} = {};

      category.items?.forEach(item => {
        if(item.isHidden){return}

        item.itemSizes = item.itemSizes.filter(size => !size.isHidden);
        if(!item.itemSizes.length){return}

        const key = category.id + item.itemId;

        if(!itemIdToUniqKeys[item.itemId]){
          itemIdToUniqKeys[item.itemId] = [];
        }
        itemIdToUniqKeys[item.itemId].push(key);

        itemsIDs.push(key);

        menuItems.push({
          ...item,
          uniqKey: key,
          categoryId: category.id,
          categorySlug: category.slug
        });

        categoryItemsSlug[item.slug] = key
      });

      itemsSlugMap[category.id] = categoryItemsSlug;
      UIMenuList.push({
        ...category,
        itemsIDs: itemsIDs,
      })
    })

    menuStore.update(
      setEntities(UIMenuList),
      setEntities(menuItems, {ref: itemsEntitiesRef}),
      state => ({
        ...state,
        categorySlugToIdMap: categorySlugMap,
        itemsSlugToIdMap: itemsSlugMap,
        itemIdToUniqKeys: itemIdToUniqKeys,
        unavailableCategories: unavailableCategories,
      }),
      setActiveId(UIMenuList.length ? UIMenuList[0].id : null)
    );
  }

  private checkCategoryAvailable(category: MenuCategoryDto): boolean {
    if(!category.schedule){return true}
    return !!this.getAvailableRange(category.schedule, getCurrentDayName());
  }

  private getAvailableRange(schedule: MenuCategoryDto['schedule'], currentDayName: DayNamesType): MenuScheduleDto | null {
    if(!schedule || !schedule[currentDayName]){return null}

    const currentDate = new Date();
    const currentTime = (currentDate.getHours() || 24) * 60 * 60 + currentDate.getMinutes() * 60 + getTimeZoneOffset() * 60;

    for(let i = 0; i < schedule[currentDayName].length; i++){
      const range = schedule[currentDayName][i];
      if(this.checkTimeToRange(range.from, range.to, currentTime)){
        return {...range};
      }
    }
    return null
  }

  private checkTimeToRange(from: string, to: string, currentTime: number): boolean {
    const startRangeParse = from.split(':');
    const endRangeParse = to.split(':');

    const start = Number(startRangeParse[0]) * 60 * 60 + Number(startRangeParse[1]) * 60 + Number(startRangeParse[2]);
    const end = Number(endRangeParse[0]) * 60 * 60 + Number(endRangeParse[1]) * 60 + Number(endRangeParse[2]);

    // Диапазон без перехода через полночь
    if (start <= end) {
      return currentTime >= start && currentTime <= end;
    }

    // Диапазон пересекает полночь
    return currentTime >= start || currentTime <= end;
  }

  isCategoryScheduleAvailable(categoryId: string): boolean {
    const category = menuStore.query(getEntity(categoryId))
    if(!category){return false}
    return this.checkCategoryAvailable(category);
  }

  getItem(itemId: string): Observable<any> {
    return menuStore.pipe(selectEntity(itemId, {ref: itemsEntitiesRef}));
  }

  getItemBySlugFromCategory(slug: string): Observable<UIMenuItemDto> {
    return this.activeCategoryId$.pipe(
      switchMap(categoryId => {
        return menuStore.pipe(
          select(state => state.itemsSlugToIdMap),
          filter(itemsSlugToIdMap => !!itemsSlugToIdMap),
          map(itemsSlugToIdMap => itemsSlugToIdMap![categoryId])
        )
      }),
      filter(itemsMap => !!itemsMap),
      switchMap(itemsMap => {
        return this.getItem(itemsMap![slug]);
      }),
    )
  }

  getItemBySizeSku(sku: string): {item: UIMenuItemDto, size: MenuItemSizeDto} | null {
    let findItem;
    let findSize;
    const items = menuStore.query(getAllEntities({ref: itemsEntitiesRef}));

    items.forEach(item => {
      item.itemSizes.forEach(size => {
        if(size.sku == sku) {
          findItem = item;
          findSize = size;
        }
      })
    });

    return findSize && findItem ? {item: findItem, size: findSize} : null;
  }

  setActiveCategory(id: string): void {
    menuStore.update(setActiveId(id));
    this.setSearchString(null);
  }

  setActiveCategoryBySlug(slug: string): void {
    const categoryMap = menuStore.query(state => state.categorySlugToIdMap);
    if(!categoryMap) {return}
    let categoryId = categoryMap.get(slug);

    /*
    * Если нет категории с текущим slug, то берем первую в списке и редеректим туда
    * */
    if (!categoryId) {
      categoryId = categoryMap.entries().next().value?.[1];
      void this.router.navigate(['/category', categoryMap.entries().next().value?.[0]]);
    }

    if(!categoryId){return}

    this.setActiveCategory(categoryId);
    this.setActiveCatalogUrl(true);
  }

  fetchStopList(): Observable<{[p: string]: number}> {
    const storeId = this.configRepository.activeOutletId;
    return this.menuService.v1MenuStopListsStoreIdGet$Json({storeId}).pipe(
      map((response => {
        if(response.error || !response.result) {
          throw response;
        }
        return response.result;
      })),
      tap(stopList => {
        const itemIdToUniqKeys = menuStore.query(state => state.itemIdToUniqKeys);
        if(!stopList || !itemIdToUniqKeys) {return}

        Object.keys(stopList).forEach(key => {
          if(!itemIdToUniqKeys[key]){return}
          itemIdToUniqKeys[key].forEach(uniqKey => {
            menuStore.update(updateEntities(uniqKey, {balance: stopList[key]},
              {ref: itemsEntitiesRef})
            )
          })
        })
      }),
      tap(stopList => {
        if (!stopList) {return}

        const stopListMap = new Map(Object.entries(stopList));
        if(stopListMap.size <= 0) {return}

        menuStore.update(state => ({
          ...state,
          stopList: stopListMap
        }))
      })
    )
  }

  setActiveCatalogUrl(force = false): void {
    if(this.location.path() !== '' && !force) {return}
    const categoryId = menuStore.query(getActiveId);
    if(!categoryId) {return}
    void this.router.navigate(['/category', menuStore.query(getEntity(categoryId))?.slug]);
  }

  getDefaultSize(item: MenuItemDto): MenuItemSizeDto {
    return item.itemSizes.find((size: MenuItemSizeDto) => size.isDefault) ?? item.itemSizes[0];
  }

  getDefaultPrice(currentSize: MenuItemSizeDto): number | null {
    const activeOutletId = this.configRepository.activeOutletId;

    return currentSize.prices.find((price: MenuPrice) => price.storeId === activeOutletId)?.price ?? null;
  }

  private sortMenuItems(items: UIMenuItemDto[]): UIMenuItemDto[] {
    const mapped = items.map((item, i) => {
      return {index: i, value: item.balance === 0 ? 1 : -1}
    });

    mapped.sort((a, b) => {return a.value - b.value})

    return mapped.map((item => items[item.index]));
  }

  private filterNullPrice(items: UIMenuItemDto[]): UIMenuItemDto[] {
    return items?.filter((item: UIMenuItemDto) => {
      const defaultSize = this.getDefaultSize(item);

      return this.getDefaultPrice(defaultSize) !== null && this.getDefaultPrice(defaultSize) !== 0;
    });
  }

  setSearchString(value: string | null): void {
    menuStore.update(setProp('searchString', value))
  }

  getItemImagesBySku(orderItems: PastOrderItemDto): {[key: string]: string } {
    let imagesSet: {[key: string]: string } = {};
    const menuItems = menuStore.query(getAllEntities({ref: itemsEntitiesRef}));

    menuItems.forEach(item => {
      if (orderItems.sku.includes(item.sku)) {
        item.itemSizes.forEach(size => {
          if (orderItems.sku.includes(size.sku)) {
            size.prices.forEach(price => {
              if (orderItems.fullUnitPrice === (price.price * orderItems.quantity) && size.buttonImage['src']) {
                imagesSet = size.buttonImage;
              }
            })
          }
        })
      }
    });

    return imagesSet;
  }

  getRelatedProducts(relatedProducts: RelatedProduct[]): Observable<UIMenuItemDto[]> {
    let keys: string[] = [];

    relatedProducts.forEach(relates => {
      if(!this.isCategoryScheduleAvailable(relates.menuCategoryId)){return}
      keys.push(relates.menuCategoryId + relates.menuItemId);
    });

    keys = [...new Set([...keys])];

    return this.getItemsByUniqKey(keys);
  }
}

/// <reference types="@angular/localize" />

import { enableProdMode, ErrorHandler } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { devTools } from '@ngneat/elf-devtools';
import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { EnvironmentTypes } from './environments/environment-types';
import { VERSION } from './environments/version';

if (environment.production) {
  enableProdMode();
}

if (environment.type === EnvironmentTypes.prod) {
  Sentry.init({
    dsn: 'https://a5f57291626f4f2b8a6b588ec69b7ea1@sentry.iiko.ru/5',
    environment: environment.type,
    release: VERSION,
    tracesSampleRate: 0,
    maxBreadcrumbs: 5,
    denyUrls: [
      'https://mc.yandex.ru',
      'https://www.google-analytics.com',
    ]
  });
}

function bootstrap(): void {
  bootstrapApplication(AppComponent, appConfig)
    .then(() => devTools())
    .catch((err: ErrorHandler) => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

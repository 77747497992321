import { Injectable } from '@angular/core';
import { createState, select, setProp, Store, withProps } from '@ngneat/elf';
import {
  getActiveEntity,
  getActiveId,
  hasEntity,
  selectActiveEntity,
  selectActiveId,
  selectAllEntities,
  setActiveId,
  setEntities,
  withActiveId,
  withEntities
} from '@ngneat/elf-entities';
import { excludeKeys, localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  combineLatestWith,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  AdditionalPageShort,
  AddressFormatType,
  Coordinates,
  CostRule,
  DeliveryZoneResponse,
  EatinConfiguration,
  Hours,
  Layout,
  LoyaltyProvider,
  OutletMode,
  ReleasedFeature,
  SeoData,
  TableSectionDto,
  WorkingHours
} from '../../api/v1/models';
import { ConfigDto } from '../../api/v1/models/config-dto';
import { Currency } from '../../api/v1/models/currency';
import { Outlet } from '../../api/v1/models/outlet';
import { OutletOpenHours } from '../interfaces/outlet-open-hours';
import { PaymentType } from '../../api/v1/models/payment-type';
import { SocialLink } from '../../api/v1/models/social-link';
import { ObjectKeyArray } from '../interfaces/object-key-array.interface';
import { getTimeZoneOffset, zeroMaskFromTimeInterval } from '../utils/get-time-intervals';
import { SeparateTask } from '../utils/separate-task';
import { SettingsService } from '../../api/v1/services/settings.service';
import { ErrorService } from '../services/error.service';
import { OrderFormStepConfig } from '../interfaces/order-form-step-config.interface';
import { selectIsRequestPending, updateRequestStatus, withRequestsStatus } from '@ngneat/elf-requests';
import { convertTZ } from '../utils/convert-timezone';

export interface ConfigModel {
  isLoading: boolean,
  settings: ConfigDto | null,
  streets: string[] | null,
  isUserChooseOutlet: boolean,
  activeZoneId: number | null,
  deliveryCoordinates: Coordinates | null,
  activeCostRules: CostRule[] | null,
  deliveryAvailableIntervals: ObjectKeyArray<WorkingHours> | null,
  userLocale: string | null,
  userPosition: {latitude: number, longitude: number} | null
}

const { state, config } = createState(
  withProps<ConfigModel>({
    isLoading: false,
    settings: null,
    streets: null,
    isUserChooseOutlet: false,
    activeCostRules: null,
    activeZoneId: null,
    deliveryCoordinates: null,
    deliveryAvailableIntervals: null,
    userLocale: null,
    userPosition: null,
  }),
  withEntities<Outlet, 'storeId'>({ idKey: 'storeId' }),
  withActiveId(),
  withRequestsStatus<'sectionListLoading'>(),
)
export const configStore = new Store({state, name: 'ConfigStore', config});

persistState(configStore, {
  key: 'outletConfigStore',
  storage: localStorageStrategy,
  source: () => configStore.pipe(excludeKeys(['ids', 'entities', 'settings', 'streets', 'isLoading', 'activeCostRules', 'activeZoneId', 'deliveryAvailableIntervals'])),
});

@Injectable({providedIn: 'root'})
export class ConfigRepository {
  constructor(
    private settingsApi: SettingsService,
    private errorService: ErrorService,
  ) {}

  fetchSectionList(): Observable<TableSectionDto[]> {
    configStore.update(updateRequestStatus('sectionListLoading', 'pending'));
    return this.settingsApi.v1SettingsSectionsStoreIdGet$Json$Response({
      storeId: this.activeOutletId,
    }).pipe(
      tap(() => configStore.update(updateRequestStatus('sectionListLoading', 'success'))),
      map(response => response.body),
      map(data => {
        if(data.error || !data.result) {
          throw data;
        }
        return data.result;
      }),
      catchError(error => {
        this.errorService.handleError(error);

        configStore.update(updateRequestStatus('sectionListLoading', 'error', error));
        throw new Error('Section list is not found');
      })
    )
  }

  isSectionListLoading$: Observable<boolean> = configStore.pipe(selectIsRequestPending('sectionListLoading'));

  defaultLocale$: Observable<string> = configStore.pipe(
    select(state => state.settings?.defaultLocale),
    filter((defaultLocale: string | undefined) : defaultLocale is string => !!defaultLocale)
  )

  loyaltyAuthProvider$: Observable<LoyaltyProvider> = configStore.pipe(
    select(state => state.settings?.loyaltySettings.provider || LoyaltyProvider.Card),
  )

  public isIiko$: Observable<boolean | null> = configStore.pipe(select(state => {
    if(!state.settings) {return null}
    return state.settings.defaultLocale === 'ru';
  }));

  private deliveryZoneChange$: BehaviorSubject<null> = new BehaviorSubject(null);
  public releasedFeatures$: Observable<ReleasedFeature[]> = configStore.pipe(select(state => state.settings?.releasedFeatures || []))

  public isLoading$: Observable<boolean> = configStore.pipe(select(state => state.isLoading));
  public isUserChooseOutlet$: Observable<boolean> = configStore.pipe(select(state => state.isUserChooseOutlet));

  public isHasSettings$: Observable<boolean> = configStore.pipe(select(state => !!state.settings));
  public logoUrl$: Observable<string | undefined | null> = configStore.pipe(select(state => state.settings?.theme?.logoImageUrl));
  public bannerUrls$: Observable<string[] | undefined | null> = configStore.pipe(select(state => state.settings?.theme?.bannerImageUrls));
  public seoData$: Observable<SeoData | null> = configStore.pipe( select(state => state.settings?.seoData ?? null));
  public sharingImageUrls$: Observable<string | undefined> = configStore.pipe(select(state => state.settings?.theme?.sharingImageUrl));

  public locale$: Observable<string | undefined | null> = configStore.pipe(select(state => state.settings?.locale));
  public socialLinks$: Observable<SocialLink[] | null> = configStore.pipe(select(state => {
    if(state.settings && state.settings.socialLinks.length) {
      return state.settings.socialLinks;
    }
    return null;
  }));

  loadSettingsSubj$ = new Subject<unknown>();

  loadedSettings$: Observable<ConfigDto | null> = this.loadSettingsSubj$.asObservable().pipe(
    switchMap(() => configStore.pipe(select(state => state.settings))),
    shareReplay({refCount: false, bufferSize: 1}),
  );

  layout$: Observable<Layout> = this.loadedSettings$.pipe(
    map(settings => settings?.frontConfig.layout || Layout.Default),
    shareReplay({refCount: false, bufferSize: 1}),
  );

  preset$: Observable<string | null> = this.loadedSettings$.pipe(
    map(settings => settings?.theme.preset || null),
    shareReplay({refCount: false, bufferSize: 1}),
  );

  footerColor$: Observable<string | null> = this.preset$.pipe(
    map(preset => {
      if (preset && preset === 'light') {
        return '#ffffff';
      } else if (preset && preset === 'dark') {
        return '#393939';
      }
      return null;
    }),
    shareReplay({refCount: false, bufferSize: 1}),
  );

  public paymentsTypes$: Observable<PaymentType[] | undefined | null> = configStore.pipe(
    select(state => state.settings?.paymentTypes),
    map(paymentTypes => paymentTypes && paymentTypes.length ? paymentTypes : null)
  );

  public get activePaymentsType(): PaymentType {
    return configStore.query(
      state => state.settings && state.settings.paymentTypes ? state.settings?.paymentTypes[0] : PaymentType.Cod
    );
  }

  public yandexAnalyticsCodes$: Observable<string[] | null | undefined> = configStore.pipe(
    select(state => state.settings?.analyticsConfig?.yandexAnalyticsCodes)
  );

  public googleAnalyticsCodes$: Observable<string[]> = configStore.pipe(select(state => {
    if (!state.settings || state.settings.defaultLocale === 'ru') {return []}
    return state.settings.analyticsConfig?.googleAnalyticsCodes || null;
  }));

  public googleTagManagerCodes$: Observable<string[]> = configStore.pipe(select(state => {
    if (!state.settings || state.settings.defaultLocale === 'ru') {return []}
    return state.settings.analyticsConfig?.googleTagManagerCodes || null;
  }));

  additionalPagesNew$: Observable<{[key: string]: AdditionalPageShort[]} | null> = configStore.pipe(select(state => state.settings?.additionalPages || null))

  public additionalAbout$: Observable<AdditionalPageShort | undefined> = configStore.pipe(
    select(state => {
      const additionalPages = state.settings?.additionalPages;

      if(!additionalPages || !additionalPages['about']){return}

      return additionalPages['about'][0] || undefined;
    })
  );
  public additionalPrivacy$: Observable<AdditionalPageShort | undefined> = configStore.pipe(
    select(state => {
      const additionalPages= state.settings?.additionalPages;

      if(!additionalPages || !additionalPages['privacy_policy']){return}

      return additionalPages['privacy_policy'][0] || undefined;
    })
  );
  public additionalPages$: Observable<AdditionalPageShort[] | undefined> = configStore.pipe(
    select(state => {
      const additionalPages= state.settings?.additionalPages;

      if (!additionalPages || !additionalPages['page']) {
        return;
      }

      return additionalPages['page'];
    }),
    map(pages => pages?.sort((a, b) => a.priority - b.priority))
  );

  public orderIntervalInMinutes$: Observable<number> = configStore.pipe(select(state => state.settings?.deliveryRestrictions?.orderIntervalInMinutes || 15));

  get orderIntervalInMinutes(): number {
    return configStore.query(state => state.settings?.deliveryRestrictions?.orderIntervalInMinutes || 15)
  }

  public exchangeRate$ : Observable<number> = configStore.pipe(select(state => state.settings?.loyaltySettings?.exchangeRate || 0));

  public outlets$: Observable<Outlet[]> = configStore.pipe(selectAllEntities());

  public activeOutlet$: Observable<Outlet> = configStore.pipe(
    selectActiveEntity(),
    filter((outlet): outlet is Outlet  => !!outlet),
    shareReplay({refCount: false, bufferSize: 1}),
  );
  public activeOutletId$: Observable<number | undefined> = configStore.pipe(selectActiveId());

  public get activeOutlet(): Outlet | undefined {
    return configStore.query(getActiveEntity());
  }
  public get activeOutletId(): number {
    return configStore.query(getActiveId);
  }

  public addressFieldSettings$ = this.activeOutlet$.pipe(
    map(outlet => {
      return {
        usesIndexSearch: outlet.usesIndexSearch,
        useUaeAddressingSystem: outlet.useUaeAddressingSystem,
        useAddressFormatType: outlet.addressFormatType === AddressFormatType.City,
      }
    })
  );

  public deliveryDurationInMinutes$: Observable<number> = this.activeOutlet$.pipe(
    map((outlet) => outlet.deliveryDurationInMinutes || 0)
  );

  get deliveryDurationInMinutes(): number {
    return this.activeOutlet?.deliveryDurationInMinutes || 0
  }

  public pickupDurationInMinutes$: Observable<number> = this.activeOutlet$.pipe(
    map((outlet) => outlet.pickupDurationInMinutes || 0)
  );

  get addressFieldSettings(): { useUaeAddressingSystem: boolean; usesIndexSearch: boolean } | null {
    const outlet = configStore.query(getActiveEntity());
    if(!outlet) {return null}
    return {
      usesIndexSearch: outlet.usesIndexSearch,
      useUaeAddressingSystem: outlet.useUaeAddressingSystem,
    }
  }

  get restoTimeZone(): string | undefined {
    const outlet = configStore.query(getActiveEntity());
    if(!outlet) {return}
    return outlet?.timeZone || undefined;
  }

  get timeZoneOffset(): number {
    const currentDate = new Date();
    const convertedDate = convertTZ(new Date(), this.restoTimeZone);
    return Math.round((convertedDate.getTime() - currentDate.getTime()) / 1000 / 60);
  }

  public address$: Observable<string | null> = this.activeOutlet$.pipe(map(outlet => outlet.address ?? null));
  public phone$: Observable<string | null> = this.activeOutlet$.pipe(map(outlet => outlet.phone ?? null));

  public get phone(): string | null {
    return configStore.query(getActiveEntity())?.phone ?? null;
  }

  outletMode$: Observable<string | null> = this.activeOutlet$.pipe(map(outlet => outlet.outletMode ?? null));

  isEatinMode$: Observable<boolean> = this.outletMode$.pipe(
    map(outletMode => outletMode === OutletMode.Eatin)
  )

  public useCoupons$: Observable<boolean> = configStore.pipe(
    select(state => state.settings?.loyaltySettings?.useCoupons ?? false)
  );

  public isLoyaltyUseBonuses$: Observable<boolean> = configStore.pipe(
    select(state => state.settings?.loyaltySettings?.useBonusPayment ?? false)
  );

  public isLoyalty$: Observable<boolean> = configStore.pipe(
    select(state => state.settings?.loyaltySettings?.useLoyalty ?? false)
  );

  public useBonusPayment$: Observable<boolean> = combineLatest([
    this.isEatinMode$,
    this.isLoyaltyUseBonuses$,
  ]).pipe(
    map(([isEatinMode, isLoyaltyUseBonuses]) => isLoyaltyUseBonuses && !isEatinMode)
  )

  eatinConfig$: Observable<EatinConfiguration | null> = configStore.pipe(
    select(state => state.settings?.eatinConfig || null)
  )

  orderFormStepConfig$: Observable<OrderFormStepConfig> = combineLatest([
    this.activeOutlet$,
    this.useCoupons$,
    this.eatinConfig$,
  ]).pipe(
    map(([outlet, useCoupons, eatinConfig]) => {
      const isEatin = outlet.outletMode === OutletMode.Eatin;

      let isShowCustomerStep = true;

      if(eatinConfig){
        isShowCustomerStep = eatinConfig.useCustomerName || eatinConfig.useCustomerPhone || eatinConfig.useCustomerEmail;
      }

      return {
        isShowTableStep: isEatin,
        isShowCustomerStep: isShowCustomerStep,
        isShowDeliveryTypeStep: !isEatin,
        isShowAddressStep: !isEatin,
        isShowExpectedDateTimeStep: !isEatin,
        isShowOrderPaymentStep: true,
        isShowNotesStep: !isEatin,
        isShowCouponStep: !isEatin && useCoupons,
      }
    })
  )

  customerStepConfig$: Observable<EatinConfiguration> = this.eatinConfig$.pipe(
    map(config => config || {
      useCustomerEmail: true,
      useCustomerPhone: true,
      useCustomerName: true,
    })
  )

  public currency$: Observable<Currency | null> = this.activeOutlet$.pipe(map(outlet => outlet?.currency ?? null)).pipe(
    shareReplay({refCount: true, bufferSize: 1}),
  );

  public isProvidingRestoSelection$: Observable<boolean> = this.outlets$.pipe(map(outlets => outlets.length > 1))
  public isProvidingCollection$: Observable<boolean> = this.activeOutlet$.pipe(map(outlet => {
    if (outlet?.outletMode) {
      return [OutletMode.PickupDelivery, OutletMode.Pickup].includes(outlet.outletMode);
    }
    return outlet?.providesCollection || false;
  }));
  public isProvidingDelivery$: Observable<boolean> = this.activeOutlet$.pipe(map(outlet => {
    if (outlet?.outletMode) {
      return [OutletMode.PickupDelivery, OutletMode.Delivery].includes(outlet.outletMode);
    }
    return outlet?.providesDelivery || false;
  }));
  public isProvidingEatIn$: Observable<boolean> = this.activeOutlet$.pipe(map(outlet => {
    return outlet?.outletMode === OutletMode.Eatin || false;
  }));

  public activeZoneId$: Observable<number | null> = configStore.pipe(select(state => state.activeZoneId));

  activeCostRules$: Observable<CostRule[] | null> = configStore.pipe(
    select((state) => state.activeCostRules),
    shareReplay({refCount: false, bufferSize: 1}),
  );
  deliveryAvailableIntervals$: Observable<ObjectKeyArray<WorkingHours> | null> = configStore.pipe(select(state => state.deliveryAvailableIntervals));
  pickupAvailableIntervals$: Observable<ObjectKeyArray<WorkingHours> | null> = this.activeOutlet$.pipe(map(outlet => outlet?.outletPickupIntervals || null));

  currentDeliveryHours$ = this.deliveryAvailableIntervals$.pipe(
    map(intervals => {
      if(!intervals) {return null}
      return this.getHourByCurrentDay(intervals);
    })
  )

  currentPickupHours$ = this.pickupAvailableIntervals$.pipe(
    map(intervals => {
      if(!intervals) {return null}
      return this.getHourByCurrentDay(intervals);
    })
  )

  public gettingOrderInterval$: Observable<(number | null | undefined)[]> = combineLatest([
    this.activeOutlet$, //Берем отсюда интервалы для самовывоза
    this.deliveryAvailableIntervals$,
  ]).pipe(map(([outlet, deliveryIntervals]) => {
    let deliveryDuration = null;
    let pickupDuration = null;

    if (outlet && outlet.outletPickupIntervals) {
      pickupDuration = this.getHourByCurrentDay(outlet.outletPickupIntervals)?.deliveryDuration || 0;
    }

    if (deliveryIntervals) {
      deliveryDuration = this.getHourByCurrentDay(deliveryIntervals)?.deliveryDuration || 0;
    }

    return [deliveryDuration, pickupDuration];
  }));

  public activeDeliveryZoneChanges$: Observable<number | null> = combineLatest([
    this.activeZoneId$,
    this.deliveryZoneChange$,
  ]).pipe(
    map(([deliveryZone]) => deliveryZone),
    shareReplay({refCount: false, bufferSize: 1})
  )

  public isDeliveryOrCollection$: Observable<boolean> = combineLatest([
    this.isProvidingDelivery$,
    this.isProvidingCollection$
  ]).pipe(
    map(([isProvidesDelivery, isProvidesCollection]) => {
        return isProvidesDelivery || isProvidesCollection;
      }
  ));

  public isBasketMode$: Observable<boolean> = combineLatest([
    this.isDeliveryOrCollection$,
    this.isProvidingEatIn$,
    this.activeOutlet$,
  ]).pipe(
      map(([isDeliveryOrCollection, isProvidesEatIn]) => {
        return isDeliveryOrCollection || isProvidesEatIn;
      }
  ));

  public langList$: Observable<string[]> = of(['ru-RU', 'en-US', 'en-GB']);

  @SeparateTask()
  setConfig(settings: ConfigDto | null): void {
    if (settings && settings.outlets && settings.outlets.length > 0) {
      this.setOutlets(settings.outlets);
    }

    configStore.update(state => ({
      ...state,
      settings: settings
    }));

    this.loadSettingsSubj$.next(null)
  }

  @SeparateTask()
  setLoadingState(isLoading: boolean): void {
    configStore.update(state => ({
      ...state,
      isLoading: isLoading
    }))
  }

  public workingHours$: Observable<OutletOpenHours | null> = this.activeOutlet$.pipe(
    map(activeOutlet => {
      if (!activeOutlet || !Object.keys(activeOutlet.outletWorkingIntervals).length) {return null}

      const currentDay = Intl.DateTimeFormat('en',{ weekday: 'long' }).format(new Date());
      const [dayHours] = activeOutlet.outletWorkingIntervals[currentDay] ?? [];

      if (!dayHours) {
        return null;
      }

      return {
        open: zeroMaskFromTimeInterval(dayHours.open),
        close: zeroMaskFromTimeInterval(dayHours.close),
        day: Intl.DateTimeFormat('en',{ weekday: 'long' }).format(new Date()),
      };
    })
  );

  setOutlets(outlets: Outlet[]): void {
    configStore.update(setEntities(outlets));

    const ids = configStore.query(state => state.ids);
    const activeId = configStore.query(getActiveId);

    if (!activeId || !ids.includes(activeId)) {
      let activeOutlet = outlets.find(outlet => outlet.providesDelivery && outlet.providesCollection);
      if (!activeOutlet) {
        activeOutlet = outlets.find(outlet => outlet.providesDelivery || outlet.providesCollection);
      }

      if (!activeOutlet) {
        activeOutlet = outlets[0];
      }

      this.setActiveOutlet(activeOutlet.storeId);
    }
  }

  setUserChooseState(isUserChooseOutlet: boolean): void {
    configStore.update(state => ({
      ...state,
      isUserChooseOutlet: isUserChooseOutlet,
    }));
  }

  setActiveOutlet(id: number): void {
    const activeId = this.activeOutletId;
    if(activeId == id) { return }

    const outletExists = configStore.query(hasEntity(id));
    if(!outletExists) { return }
    configStore.update(setActiveId(id));
  }

  get activeZoneId(): number | null {
    return configStore.query(state => state.activeZoneId);
  }

  get coordinates(): Coordinates | null {
    return configStore.query(state => state.deliveryCoordinates);
  }

  setActiveDeliveryZone(deliveryZone: DeliveryZoneResponse | null): void {
    configStore.update(state => ({
      ...state,
      activeZoneId: deliveryZone ? deliveryZone.deliveryZoneId : null,
      deliveryCoordinates: deliveryZone ? deliveryZone.coordinates : null,
      deliveryAvailableIntervals: deliveryZone ? deliveryZone.workIntervals : null,
    }));
  }

  setActiveCostRules(rules: CostRule[] | null): void {
    configStore.update(state => ({
      ...state,
      activeCostRules: rules
    }));
  }

  getOpenStateOutlet(hours: Hours[]): boolean {
    const firstHour = hours[0];
    const lastHour = hours[hours.length - 1];

    if(!firstHour || !lastHour) {return false}
    if(!firstHour.open || !lastHour.close) {return false}

    const openTime: number = firstHour.open.hour * 60 + firstHour.open.minute;
    const closeTime: number = lastHour.close.hour * 60 + lastHour.close.minute;

    const currentTime = new Date().getHours() * 60 + new Date().getMinutes();

    if(openTime < closeTime) {
      return currentTime > openTime && currentTime < closeTime;
    }

    return currentTime > openTime ? true : currentTime < closeTime;
  }

  private getHourByCurrentDay(hours: ObjectKeyArray<WorkingHours>): WorkingHours | null {
    const currentDay = Intl.DateTimeFormat('en',{ weekday: 'long' }).format(new Date());
    return hours[currentDay] ? hours[currentDay][0] : null;
  }

  userLocale$: Observable<string | null> = configStore.pipe(select(state => state.userLocale));
  get userLocale(): string | null {
    return configStore.query(state => state.userLocale)
  }
  setUserLocale(locale: string){
    configStore.update(setProp('userLocale', locale))
  }

  currentLocale$: Observable<string> = this.userLocale$.pipe(
    combineLatestWith(this.defaultLocale$),
    map(([userLocale, defaultLocale]) => userLocale ? userLocale : defaultLocale)
  )

  promotionsCountByLang$: Observable<{[key: string]: number}> = configStore.pipe(
    select(state => state.settings?.promotions || null),
    filter((promotionsCounts): promotionsCounts is { [key: string]: number } => !!promotionsCounts)
  )

  promotionCount$: Observable<number> = this.promotionsCountByLang$.pipe(
    combineLatestWith(this.currentLocale$),
    map(([promotionsCountByLang, currentLocale]) => {
      if(promotionsCountByLang[currentLocale]){
        return promotionsCountByLang[currentLocale];
      }

      return 0;
    })
  )

  userPosition$: Observable<{latitude: number, longitude: number} | null> = configStore.pipe(
    select(state => state.userPosition)
  )
  setUserPosition(position: {latitude: number, longitude: number} | null){
    configStore.update(setProp('userPosition', position))
  }
}
